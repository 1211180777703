.card {
    border: 1px solid gray;
    border-radius: 5px;
    width: 400px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-header {
    display: flex;
    margin: 10px; 
}
.card-header__name {
    font-weight: bold;
}
.card-header__place {
    color: gray;
}

.card-header span {
    margin-left: auto;
    align-self: center;
}
.card-image{
    width: 100%;
    height: 300px;
}
.card-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card-actions {
    padding: 10px;
}

.card-actions span {
    margin-right: 10px;
    font-size: 12px;
}
.card-actions span:last-child {
    float: right;
}

.likes {
    margin-left: 0px;
    color: gray;
    font-size: 12px;
}

.description {
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}


.desc-section{
    padding: 5px 20px;
  }
  .heart-rocket-likes img{
    width: 17px;
    margin-right: 15px;
  }
  .heart-rocket-likes .likes{
    color: #a5a5a5;
    font-size: 13px;
  }

  .upper-desc{
    display: flex;
    justify-content: space-between;
  }
  .desc-section .date{
    color: #a5a5a5;
    font-size: 14px;
    font-weight: 500;
  }
  .desc-section .description{
    margin-top: 5px;
    font-weight: 600;
  }