.header {
    display: flex;
    border-bottom: 1px solid gray;
    padding: 5px;
    width: 80%;
    margin: 0 auto;
}
.camera-icon {
    align-self: center;
    margin-left: auto;
    margin-right: 30px;
}
.camera-icon img {
    width: 50px;
}
#clone{
    width: 150px;
}